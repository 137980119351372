<template>
  <section class="gold-card p-2">
    <editCard
      v-if="editable"
      :modal_name="'gcmeb' + sectionTitle"
      :entitytypes="['products']"
      @edit-card-com="changeOption($event)"
      :option="options"
      :parentData="pdata"

    />

    <!-- <div class="row product-item-wrapper scrollcards" @click="productInfo()"> -->
    <div class="row product-item-wrapper scrollcards">
      <div
        class="col-md-3 col-sm-12 my-3 scrollcards-item"
        v-for="item in items"
        :key="item.id"
      >
        <div class="card justify-content-center">
          <img
            class="card-img-top"
            :src="item['card-image-url']"
            alt="Card image cap"
          />
          <div class="card-body p-3">
            <h5 class="card-title" v-if="isDisplay('title')">Vernis à ongle</h5>
            <div class="d-flex justify-content-between">
              <p class="card-text" v-if="isDisplay('description')">
                Lorem, ipsum dolor sit amet consectetur adipisicing elit
              </p>
              <h2 class="text-muted" v-if="isDisplay('prix')">7€</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import gold_card from "../../pages/json/gold-card.json";
import editCard from "./edit-card.vue";

export default {
  name: "gold-card",
  components: {
    editCard,

  },
  data() {
    return {
      items: [],
      selectedDisplay: [],
      sel_entity:[],
      coll_Checked:false,
      scroll_card:this.scrollcards,
      options: {
        collection: false,
        display: [
          { titre: "Description", value: "description" },
          { titre: "Titre", value: "title" },
          { titre: "Prix", value: "prix" },
        ],
      },
      pdata:{
       selentity:this.entitytype,
       sdisplay:this.descdisplay,
       scroll:this.scrollcards
     }
    };
  },
  props: {
    id: String,
    scrollcards: Boolean,
    entitytype: String,
    card: Array,
    title: String,
    description: String,
    price: String,
    column: String,
    sectionTitle: String,
    editable: Boolean,
    descdisplay: null,
  },
  methods: {
    getEntitytype() {
      if (this.entitytype) {
        axios
          .get(`https://sites.comolapapaya.com/${this.entitytype}`)
          .then((resEntitytype) => {
            this.items = resEntitytype.data.result;
          })
          .catch((errEntitytype) => {
            console.log(errEntitytype);
          });
      } else if (this.card) {
        this.items = this.card;
      } else {
        this.items = [];
      }
    },
    changeOption(data) {
      this.selectedDisplay = data["display"],
      this.coll_Checked = data["collection"],
      this.sel_entity = data["entity"],
      this.scroll_card = data["scroll"];
      this.saveCard();
    },
    isDisplay(el) {
      if (this.selectedDisplay.includes(el)) {
        return true;
      } else {
        return false;
      }
    },

    saveCard() {
      if (!this.collectionChecked) {
        //si individuel
        console.log(this.selectedDisplay);
        this.$emit("saveGoldCard", {
          section: {
            title: "nouvel ajout",
            blocks: [
              {
                element: "gold-card-meb",
                data: {
                  display: this.selectedDisplay,
                  card: gold_card,
                  scrollcards:this.scroll_card
                },
              },
            ],
          },
          blocks: [
            {
              element: "gold-card-meb",
              data: {
                display: this.selectedDisplay,
                card: gold_card,
                scrollcards:this.scroll_card

              },
            },
          ],
        });
      } else {
        //si collection
        this.$emit("saveGoldCard", {
          section: {
            title: "ajout de collection",
            blocks: [
              {
                entitytype: "products",
                element: "gold-card-meb",
                data: {
                  display: this.selectedDisplay,
                  card: gold_card,
                  scrollcards:this.scroll_card

                },
              },
            ],
          },
          blocks: [
            {
              entitytype: "products",
              element: "gold-card-meb",
              data: {
                display: this.selectedDisplay,
                card: gold_card,
                scrollcards:this.scroll_card

              },
            },
          ],
        });
      }
    },
    //scrollcards
    scrollright() {
      console.log(this.id);
      document.getElementById(`${this.id}`).scrollLeft += 1000;
    },
    scrollleft() {
      console.log(this.id);
      document.getElementById(`${this.id}`).scrollLeft -= 1000;
    },
    deleteCard(item) {
      let index = this.items.indexOf(item);
      console.log(index);
      this.items.splice(index, 1);
    },
    productInfo() {
      this.$router.push({
        name: "product",
      });
    },
  },
  mounted() {
    this.getEntitytype();
    if (this.descdisplay.includes("description")) {
      this.selectedDisplay.push("description");
    }
    if (this.descdisplay.includes("title")) {
      this.selectedDisplay.push("title");
    }
    if (this.descdisplay.includes("prix")) {
      this.selectedDisplay.push("prix");
    }
  },
};
</script>

<style scoped>
.scrollcards {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  scrollbar-width: thin;
}
.scrollcards-item {
  /* display: inline-block !important */
  flex: 0 0 auto;
}

.carousel-control-prev-icon {
  background-image: url("../../assets/images/import/arrow-preview.png");
  width: 50px;
  height: 50px;
  margin: 0px 81px 0px -122px;
}
.carousel-control-next-icon {
  background-image: url("../../assets/images/import/arrow-next.png");
  width: 50px;
  height: 50px;
  margin: 0px -121px 0px 79px;
}

img {
  width: auto;
  height: 300px;
  object-fit: cover;
}

.card {
  padding: 1rem;
  position: relative;
  background: linear-gradient(90deg, rgba(197,140,35,1) 0%, rgba(242,229,151,1) 35%, rgba(197,140,35,1) 68%, rgba(242,229,151,1) 100%);
  padding: 5px
}

.card-body {
  border: black 1px solid;
  background: #fff
}
</style>